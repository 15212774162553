import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './components/mysvg.css'
import './Back.css'
class Back extends React.Component {
  render(){
      return (
      <div id='dots' className="bgsvg">
      </div>
    );
    }
}

export default Back;
